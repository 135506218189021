import axios from "axios";

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: "application/json'",
    "Content-Type": "application/json"
  }
});

export default {
  getAtcs() {
    const baseUrl = process.env.VUE_APP_WP_ATC_API;
    return apiClient.get(baseUrl);
  },
}