<template>
  <div class="atc-country">
    <div class="inner">
      <h2 class="h3">{{ location[0] }}</h2>
      <h3 class="h4" v-if="location[1] !== ''">{{ location[1] }}</h3>
      <hr/>
      <div class="flex">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtcCountry",

  props: [
    "location"
  ]
}
</script>

<style scoped>
.atc-country {
  background-color: var(--event5);
  padding: 26px 20px;
}

h2 {
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 12px;
}

h3 {
  color: var(--warm2);
  padding: 8px 0;
}

hr {
  border: none;
  border-top: 1px solid var(--warm2);
  margin-bottom: 20px;
}

.flex {
  display: flex;
  flex-flow: row wrap;
}

@media only screen and (min-width: 768px) {
  .atc-country {
    padding: 26px min(6vw, 90px) 20px;
  }

  .inner {
    width: 100%;
    max-width: 710px;
  }
}
</style>
