<template>
  <div>
    <div class="loading" v-if="loading">
      <img src="https://s3.amazonaws.com/s3.pilates.com/img/loader.gif" alt="Stay tuned"/>
    </div>
    <div v-else class="atc">
      <div class="content">
        <nav class="top-nav narrow">
          <router-link to="scholarship">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 5L3.83 5L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7L16 7V5Z" fill="white"/>
            </svg>
            Scholarships Overview
          </router-link>
        </nav>
        <header class="header narrow">
          <h1 class="h3">Community Expansion Scholarship Program</h1>
          <h1 class="h3 subhead">Participating Authorized Training Centers</h1>
          <div class="btn-flex">
            <a href="" v-show="!toggle" @click.prevent="openFilter">
              <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6499 0.5H0.649902L7.4499 8.541V14.1L10.8499 15.8V8.541L17.6499 0.5Z" stroke="#E9E6E0" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="h5">Filter by region</span>
            </a>
            <a href="" v-show="toggle" @click.prevent="clearFilter">
              <span class="h5">CLEAR FILTER</span>
            </a>
          </div>
        </header>
        <header class="header wide">
          <h1 class="h3">Community Expansion Scholarship Program</h1>
          <h1 class="h3 subhead">Participating Authorized Training Centers</h1>
        </header>
        <atc-country
          v-for="(location, index) in selectedLocation"
          :key="index"
          :location="location"
        >
          <div
            v-for="(atc, index) in getFilteredAtcArr(location[0], location[1])"
            :key="index"
            class="card-wrapper"
          >
            <atc-card :atc="atc" />
          </div>
        </atc-country>
      </div>
      <div class="col-filter wide">
        <nav class="top-nav">
          <router-link to="scholarship">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 5L3.83 5L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7L16 7V5Z" fill="white"/>
            </svg>
            Scholarships Overview
          </router-link>
        </nav>
        <div class="label h4">Filter by Region</div>
        <hr/>
        <div class="filter-by">
          <div
            v-for="(location, index) in locations"
            :key="index"
          >
            <div class="filter-flex" @click="filterBy(location)">
              <div class="h4">
                {{ location[0] }}<br/><span>{{ location[1] }}</span>
              </div>
              <div class="h4">
                {{ atcByLocation[index].length }}
              </div>
            </div>
          </div>
          <a href="" v-show="toggleWide" @click.prevent="clearFilter">
            <span class="h5">CLEAR FILTER</span>
          </a>
        </div>
      </div>
      <div class="filter narrow" v-if="filter">
        <nav class="top-nav">
          <a href="" @click.prevent="closeFilter">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 5L3.83 5L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7L16 7V5Z" fill="white"/>
            </svg>
            Back to Authorized Training Centers
          </a>
        </nav>
        <div class="filter-by">
          <div
            v-for="(location, index) in locations"
            :key="index"
          >
            <div class="filter-flex" @click="filterBy(location)">
              <div class="h4">
                {{ location[0] }}<br/><span>{{ location[1] }}</span>
              </div>
              <div class="h4">
                {{ atcByLocation[index].length }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService.js";
import AtcCountry from "@/components/AtcCountry.vue";
import AtcCard from "@/components/AtcCard.vue";

export default {
  name: "Atc",

  metaInfo: {
    title: "Community Expansion Scholarship Program",
    titleTemplate: "%s | Diversity in Pilates",
    meta: [
      {
        name: "description",
        content: "Participating Authorized Training Centers"
      }
    ]
  },

  components: {
    AtcCountry,
    AtcCard
  },

  data() {
    return {
      loading: false,
      atcs: [],
      filter: false,
      toggle: false,
      toggleWide: false,
      locations: [],
      selectedLocation: [],
      atcByLocation: [],
    }
  },

  created() {
    this.getAtcs();
  },

  methods: {
    getAtcs() {
      this.loading = true;
      ApiService.getAtcs()
        .then(response => {
          this.atcs = response.data;
          this.loading = false;
          this.getLocationsArr();
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },

    getLocationsArr() {
      const locationsArr = [];
      this.atcs.forEach( arrayItem => {
        const locationArr = [];
        locationArr.push(arrayItem.atc_country);
        locationArr.push(arrayItem.atc_region);
        locationsArr.push(locationArr);
      });
      const uniqueLocationsArr = [...new Set(locationsArr)];
      const stringArray = uniqueLocationsArr.map(JSON.stringify);
      const uniqueStringArray = new Set(stringArray);
      this.locations = Array.from(uniqueStringArray, JSON.parse);
      this.locations = this.locations.sort();
      this.selectedLocation = this.locations;
      this.getAtcByLocation();
    },

    getFilteredAtcArr(country, region) {
      let resultArr = [];
      for( let i = 0; i < this.atcs.length; i++ ) {
        if( this.atcs[i].atc_country === country && this.atcs[i].atc_region === region) {
          resultArr.push(this.atcs[i]);
        }
      }
      return resultArr;
    },

    getAtcByLocation() {
      this.atcByLocation = [];
      var self = this;
      this.locations.forEach(function (arrayItem) {
        let resultArr = [];
        for( let i = 0; i < self.atcs.length; i++ ) {
          if( self.atcs[i].atc_country === arrayItem[0] && self.atcs[i].atc_region === arrayItem[1]) {
            resultArr.push(self.atcs[i]);
          }
        }
        self.atcByLocation.push(resultArr);
      })
    },

    openFilter() {
      this.getLocationsArr();
      document.querySelector("body").classList.add("overflow-hidden");
      this.filter = true;
      this.toggle = !this.toggle;
    },

    closeFilter() {
      document.querySelector("body").classList.remove("overflow-hidden");
      this.filter = false;
    },

    filterBy(location) {
      this.selectedLocation = [];
      this.selectedLocation.push(location);
      document.querySelector("body").classList.remove("overflow-hidden");
      this.filter = false;
      this.toggleWide = true;
    },

    clearFilter() {
      this.getLocationsArr();
      this.toggle = !this.toggle;
      this.toggleWide = false;
    }
  }
}
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.top-nav {
  background-color: var(--warm4);
  padding: 25px 30px;
  border-bottom: 1px solid #B8A69B;
}

.top-nav a {
  text-decoration: none;
  color: var(--white);
  font-size: 15rem;
  font-weight: 500;
}

.header {
  background-color: var(--warm4);
  padding: 20px 20px 35px;
  border-bottom: 1px solid #B8A69B;
}

.header h1 {
  color: var(--green2);
}

.header a,
.filter-by a {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--warm2);
  border-radius: 5px; 
  padding: 10px 17px 10px 10px;
  margin-top: 36px;
}

.header span {
  color: var(--warm2);
  margin-left: 5px;
}

.card-wrapper {
  flex: 0 0 48%;
  max-width: 220px;
  overflow: hidden;
  margin: 3px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  background-color: var(--white);
  border-radius: 5px;
}

.filter {
  background-color: var(--warm4);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
}

.filter-flex {
  display: flex;
  justify-content: space-between;
}

.filter-flex:hover,
.filter-flex:active {
  text-decoration: underline;
  color: var(--white);
}

.filter-flex:hover .h4,
.filter-flex:active .h4 {
  color: var(--white);
}

.filter-by {
  padding: 25px 105px 25px 55px;
}

.filter-by .h4 {
  color: #d4d1ca;
  font-style: normal;
  font-weight: bold;
  font-size: 15rem;
  line-height: 1.2;
  text-transform: uppercase;
  padding: 15px 0;
}

.filter-by .h4 span {
  font-style: normal;
  font-weight: normal;
  font-size: 16rem;
  line-height: 1.81;
  text-transform: none;
}

.h3.subhead {
  font-size: clamp(20rem, 5vw, 23rem);
  margin-top: 7px;
}

.loading {
  position: relative;
  top: 5%;
  left: 50%;
  transform: translateX(-16px);
}

@media only screen and (min-width: 768px) {
  .narrow {
    display: none !important;
  }

  .wide {
    display: block !important;
  }

  .atc {
    display: flex;
    justify-content: center;
  }

  .col-filter {
    order: 1;
    flex: 0 0 268px;
    width: 268px;
    background-color: var(--warm4);
    border: 1px solid #B8A69B;
  }

  .filter-by {
    padding: 20px 40px 20px 40px;
    cursor: pointer;
  }

  .label {
    color: #F7FAE3;
    padding: 80px 40px 0;
  }

  hr {
    width: 44px;
    border: none;
    border-bottom: 1px solid #E9E6E0;
    padding-top: 10px;
    margin-left: 40px;
  }

  .content {
    order: 2;
    width: 890px;
    background-color: var(--event5);
  }

  .header {
    background-color: var(--event5);
    padding: 55px min(6vw, 90px) 30px;
    border-bottom: none;
  }

  .header .h3 {
    max-width: 710px;
  }

  .card-wrapper {
    margin: 8px;
  }

  .filter-by a .h5 {
    color: #d4d1ca;
    cursor: pointer;
  }
}
</style>
