<template>
  <div class="atc-card">
    <div class="logo">
      <img :src="atc.atc_logo" alt="ATC image" class="atc-logo" />
    </div>
    <div class="wrapper">
      <div class="title">{{ atc.atc_title }}</div>
      <div class="address">
        <span><b>{{ atc.atc_name }}</b></span><br/>
        <span>{{ atc.atc_address }}</span><br/>
        <span>{{ atc.atc_city}}, {{ atc.atc_state }} {{ atc.atc_country}}</span>
      </div>
      <div class="phone">{{ atc.atc_phone }}</div>
      <a class="email" :href="`mailto:${atc.atc_email}`">{{ atc.atc_email }}</a>
    </div>
    <div class="tabs">
      <a v-if="atc.atc_ig" :href="atc.atc_ig" class="social ig" target="_blank"><span class="screenreader">Visit Instagram</span></a>
      <a v-if="atc.atc_fb" :href="atc.atc_fb" class="social fb" target="_blank"><span class="screenreader">Visit Facebook</span></a>
      <a v-if="atc.atc_url" :href="atc.atc_url" class="social web" target="_blank"><span class="screenreader">Visit Website</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtcCard",

  props: [
    "atc"
  ]
}
</script>

<style scoped>
.atc-card {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  padding: 8px 0;
}
.atc-logo {
  height: 80px;
  width: 140px;
}

.wrapper {
  padding: 18px 8px 25px;
}

b {
  font-weight: 500;
}

.title {
  font-weight: 500;
  font-size: 14rem;
  line-height: 1.143;
  color: #3e3c53;
  margin-bottom: 30px;
}

.address {
  font-style: normal;
  font-weight: 400;
  font-size: 14rem;
  line-height: 1.286;
  color: #555658;
  margin-bottom: 20px;
}

.phone {
  font-style: normal;
  font-weight: 400;
  font-size: 16rem;
  line-height: 1.41;
  color: #555658;
  margin-bottom: 5px;
}

a.email {
  font-style: normal;
  font-weight: 400;
  font-size: 14rem;
  line-height: 1.286;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: var(--link);
  text-decoration: none;
}

a.email:hover,
a.email:active {
  text-decoration: underline;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto
}

.tabs img {
  height: 36px;
}

.tabs a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs a:first-of-type {
  border-bottom-left-radius: 8px;
}

.ig,
.fb,
.web {
  background-color: #fff;
  height: 46px;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
}

.ig {
  background-size: 34px;
  background-image: url('~@/assets/images/ig.svg');
}

.fb {
  background-size: 18px;
  background-image: url('~@/assets/images/fb.svg');
}

.web {
  background-size: 34px;
  background-image: url('~@/assets/images/web.svg');
}

a:hover.ig {
  background-image: url('~@/assets/images/ig-clr.svg');
}

a:hover.fb {
  background-image: url('~@/assets/images/fb-clr.svg');
}

a:hover.web {
  background-image: url('~@/assets/images/web-clr.svg');
}

.tabs a.social {
  height: 60px;
  border-top: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  flex: 1 0 33.33%;
}

.tabs a.social:last-of-type {
  border-right: none;
}

.screenreader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

</style>
